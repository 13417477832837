<template>
  <div class="gameWrap">
    <div class="sizeBox"></div>
    <!-- 轮播图广告 -->
    <AdvSwiper :advList="advList" v-if="advList.length" class="advSwiper" />
    <!-- 公告 -->
    <van-notice-bar
      mode="closeable"
      class="marquee"
      :text="marqueeText"
      background="#ffffff00"
      color="#4d4d4d"
      speed="40"
      :scrollable="true"
      @close="closeNotice"
      v-if="marqueeText && showNotice"
    >
      <template slot="left-icon">
        <div class="left_icon">
          <svg-icon iconClass="notice"></svg-icon>
          <span>公告：</span>
        </div>
      </template>
    </van-notice-bar>
    <!-- 钱包 -->
    <div class="walletBox">
      <div class="leftBox">
        <div class="userId">ID: {{ userInfo.uid }}</div>
        <div class="gameBalance">
          ¥
          <span>{{ $store.getters.userInfo.amount }}</span>
          <div @click="queryGameBalance" class="refreshBox">
            <svg-icon class="refreshBtn" iconClass="refreshBtn" id="refresh" />
          </div>
        </div>
      </div>
      <div class="rightBox">
        <router-link tag="div" to="/myWallet" class="optItem gameRecharge"></router-link>
        <div class="optItem gameWithdraw" @click="toWithdraw"></div>
        <div class="optItem gameBind" @click="jumpPhone" v-if="!$store.getters.userInfo.mobile"></div>
      </div>
    </div>
    <div
      class="gameArea"
      :class="[
        { gameAreaOne: !advList.length && !marqueeText },
        { gameAreaTwo: advList.length && !(marqueeText && showNotice) },
        { gameAreaThree: !advList.length && marqueeText && showNotice },
      ]"
    >
      <div class="manufacturersBox overflow-y-auto" id="tabBar">
        <div
          class="manufacturers"
          :class="{ mSelected: currentIndex == index }"
          @click="changeM(item, index)"
          v-for="(item, index) in manufacturersList"
          :key="item.id"
        >
          <ImgDecypt class="mIcon" :src="item.profile" />
          <div class="name" :class="{ nameColor: currentIndex == index }">{{ item.name }}</div>
        </div>
      </div>
      <div class="gameBox overflow-y-auto">
        <van-tabs animated background="rgb(239,239,239)" title-inactive-color="rgb(102,102,102)" title-active-color="rgb(255,255,255)">
          <van-tab title="热门推荐">
            <div class="gameList" v-if="gameList">
              <div class="gameInfoItem" v-for="item in gameList" :key="item.id" @click="playgame(item)">
                <ImgDecypt class="game" :src="item.profile" :key="item.profile" />
              </div>
            </div>
            <div class="tip" v-else>敬请期待~</div>
          </van-tab>
          <van-tab title="最近玩过">
            <div class="gameList" v-if="playedGame.length > 0">
              <div class="gameInfoItem" v-for="historyItem in playedGame" :key="'history' + historyItem.id" @click="playgame(historyItem)">
                <ImgDecypt class="game" :src="historyItem.profile" :key="'history' + historyItem.profile" />
              </div>
            </div>
            <NoData v-else />
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { gamePMD, gameBalance, queryGameList } from '@/api/game';
import { AdType, getAdItem } from '@/utils/getConfig';
import { NoticeBar, Toast } from 'vant';
import { mapGetters } from 'vuex';
import ImgDecypt from '@/components/ImgDecypt';
import AdvSwiper from '@/components/AdvSwiper';
import NoData from '@/components/NoData';
import { getSessionItem, setSessionItem } from '@/utils/longStorage';

export default {
  name: 'game',
  components: {
    AdvSwiper,
    ImgDecypt,
    NoData,
    [NoticeBar.name]: NoticeBar,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  data() {
    return {
      advList: [],
      marqueeText: '',
      lock: false, // 刷新金额的锁
      manufacturersList: [], // 游戏厂商列表
      gameList: [], // 当前的游戏列表
      currentIndex: '', // 当前游戏厂商下标
      currentM: '', // 当前游戏厂商
      playedGame: [], // 玩过的游戏列表
      scrollDistance: 0, // 滚动的距离
    };
  },
  created() {
    this.showNotice = getSessionItem('showGameNotice') === 'false' ? false : true;
  },
  // created() {
  //   this.getGameList();
  //   this.advList = getAdItem(AdType.GAME_CENTER);
  //   this.queryAnnouncement();
  // },
  mounted() {
    this.getGameList();
    this.advList = getAdItem(AdType.GAME_CENTER);
    this.queryAnnouncement();
  },
  activated() {
    document.getElementById('tabBar')?.scrollTo(0, this.scrollDistance);
  },
  methods: {
    // 获取游戏列表
    async getGameList() {
      let ret = await this.$Api(queryGameList);
      let obj = {
        name: '测试游戏',
      };
      if (ret && ret.code == 200) {
        let arr = ret.data.list || [];
        this.manufacturersList = arr;
        this.changeM(arr[0], 0); // 默认第一个游戏
      }
    },
    // 切换游戏厂商
    changeM(m, index) {
      this.currentIndex = index;
      this.currentM = m; // 当前游戏厂商
      this.gameList = m.subCategories; // 赋值游戏列表
      this.getHistoryGame(); // 获取历史游戏记录
    },
    // 获取历史游戏记录
    getHistoryGame() {
      this.playedGame = localStorage.getItem(this.currentM.name);
      if (!this.playedGame) {
        localStorage.setItem(this.currentM.name, JSON.stringify([]));
        this.playedGame = [];
      } else {
        this.playedGame = JSON.parse(this.playedGame);
      }
    },
    // 获取游戏公告
    async queryAnnouncement() {
      let ret = await this.$Api(gamePMD);
      if (ret && ret.code == 200) {
        if (ret.data.list) {
          ret.data.list.forEach((i) => {
            this.marqueeText += `${i.content}                `;
          });
        }
      }
    },
    // 获取游戏余额
    async queryGameBalance() {
      if (this.lock) return Toast('请勿频繁操作');
      this.lock = true;
      let svg = document.querySelector('#refresh');
      svg.classList.add('refresh-icon');
      try {
        let ret = await this.$Api(gameBalance);
        if (ret && ret.code == 200) {
          let userInfo = this.$store.getters.userInfo;
          userInfo.amount = ret.data.amount;
          this.$store.commit('user/SET_USERINFO', userInfo);
          Toast('余额刷新成功');
        } else {
          Toast(ret.msg || '余额刷新失败');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.lock = false;
        svg.classList.remove('refresh-icon');
      }
    },
    // 提现
    toWithdraw() {
      let mobile = this.$store.getters.userInfo.mobile;
      if (!mobile) return Toast('请先绑定手机号');
      this.$router.push('/withdraw');
    },
    // 手机号跳转
    jumpPhone() {
      let userInfo = this.$store.getters.userInfo;
      if (userInfo.mobile) {
        this.$router.push({
          path: '/phonePage',
          query: { phone: userInfo.mobile },
        });
      } else {
        this.$router.push('/bindPhone');
      }
    },
    // 玩游戏
    playgame(item) {
      let L = this.playedGame.length;
      for (let i = 0; i < L; i++) {
        if (this.playedGame[i].id == item.id) {
          this.playedGame.splice(i, 1);
          break;
        }
      }
      this.playedGame.unshift(item);
      localStorage.setItem(this.currentM.name, JSON.stringify(this.playedGame));
      this.$router.push({
        path: '/gameplay',
        query: {
          gameInfo: JSON.stringify(item),
        },
      });
      this.scrollDistance = Number(document.getElementById('tabBar')?.scrollTop);
    },
    closeNotice() {
      setSessionItem('showGameNotice', 'false');
    },
  },
};
</script>
<style lang="scss" scoped>
.gameWrap {
  height: 100%;
  .sizeBox {
    height: 10px;
  }
  .advSwiper {
    height: 139px;
    width: 334px;
    margin: 0px auto 10px;
    border-radius: 6px;
    /deep/ .adv-container {
      overflow: hidden;
      border-radius: 6px;
      height: 139px;
      transform: translateZ(0px);
      .my-swipe {
        height: 139px;
        .adv-swiper-slide {
          height: 139px !important;
          width: 334px !important;
        }
      }
      img {
        height: 100%;
      }
    }
    /deep/ img {
      height: 100%;
      object-fit: fill;
    }
  }
  .noticeBox {
    margin: 10px 20px 0px;
    position: relative;
    .noticeCloseIcon {
      position: absolute;
      z-index: 1;
    }
  }
  .marquee {
    height: 34px;
    margin: 10px 20px 0px;
    padding: 0 12.5px;
    box-sizing: border-box;
    .svg-icon {
      width: 17px;
      height: 20px;
      margin-right: 13.3px;
    }
    .left_icon {
      font-size: 12px;
      display: flex;
      align-items: center;
    }
    /deep/ .van-notice-bar__content {
      font-size: 12px;
    }
  }
  .walletBox {
    margin: 0 20px;
    height: 75.5px;
    border-radius: 10px;
    background-image: url('./../../assets/png/gameBalanceBox.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    .leftBox {
      margin: 12.8px 0 0 12.8px;
      .userId {
        font-size: 14px;
        color: #2b2325;
      }
      .gameBalance {
        color: #f84e85;
        font-size: 14px;
        display: flex;
        align-items: center;
        span {
          font-size: 20px;
          margin: 0 10.6px 0 2px;
        }
        .refreshBox {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .refreshBtn {
          width: 16px;
          height: 16px;
        }
      }
    }
    .rightBox {
      display: flex;
      margin-top: 8.8px;
      .optItem {
        width: 41px;
        height: 46px;
        margin-right: 9px;
      }
      .gameRecharge {
        background: url('./../../assets/png/gameRecharge.png');
        background-size: 100% 100%;
      }
      .gameWithdraw {
        background: url('./../../assets/png/gameWithdraw.png');
        background-size: 100% 100%;
      }
      .gameBind {
        background: url('./../../assets/png/gameBind.png');
        background-size: 100% 100%;
      }
    }
  }
  .gameArea {
    margin-top: 9px;
    display: flex;
    height: calc(100vh - 364px);
    border-top: 1px solid rgb(242, 242, 242);
    .manufacturersBox {
      width: 90px;
      background-color: rgb(252, 247, 247);
      height: 100%;
      .manufacturers {
        height: 68px;
        width: 68px;
        background: url('./../../assets/png/unselectGameBg.png') no-repeat;
        background-size: 100% 100%;
        margin: 11px auto 0;
        position: relative;
        .mIcon {
          width: 49px;
          height: 37px;
          position: absolute;
          top: 7px;
          left: 50%;
          transform: translate(-50%, 0);
          /deep/ .laz_com {
            background-color: #00000000 !important;
          }
        }
        .name {
          width: 100%;
          text-align: center;
          font-size: 11px;
          color: rgb(118, 63, 41);
          position: absolute;
          bottom: 8px;
        }
        .nameColor {
          color: white;
        }
      }
      .manufacturers:last-of-type {
        margin-bottom: 50px;
      }
      .mSelected {
        height: 68px;
        width: 68px;
        background: url('./../../assets/png/selectGameBg.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .gameBox {
      flex: 1;
      height: 100%;
      padding-top: 9px;
      .tip {
        text-align: center;
        font-size: 20px;
        margin-top: 100px;
      }
      .gameList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 14px;
        grid-column-gap: 7px;
        box-sizing: border-box;
        margin: 10px 0 60px 0;
        .gameInfoItem {
          width: 115.5px;
          height: 90px;
          background: #000;
          border-radius: 10px;
          overflow: hidden;
          .game {
            border-radius: 6px;
            overflow: hidden;
            /deep/ img {
              border-radius: 6px;
              overflow: hidden;
              object-fit: fill !important;
            }
          }
        }
      }
    }
  }
  .gameAreaOne {
    height: calc(100vh - 165px);
  }
  .gameAreaTwo {
    height: calc(100vh - 331px);
  }
  .gameAreaThree {
    height: calc(100vh - 209px);
  }
  :deep(.van-tabs) {
    padding: 0 20px;
    .van-tabs__wrap {
      height: 28px !important;
      border-radius: 7px;
      overflow: hidden;
    }
    .van-tabs__nav--line {
      padding-bottom: unset;
    }
    .van-tab {
      font-size: 12px;
      border-radius: 7px;
      overflow: hidden;
    }
    .van-tab--active {
      background-color: rgb(253, 125, 159);
    }
    .van-tabs__line {
      display: none;
    }
  }
  :deep(.nodata_box) {
    padding-top: 40px;
    padding-bottom: 50px;
    .icon {
      width: 160px;
    }
  }
}
.refresh-icon {
  transform: rotate(180deg);
  transition: transform 0.6s ease;
}
</style>
