import axios from '@/utils/request';

// 点击游戏列表
export function gameUrl(data) {
  return axios.post(`game/launchGame`, data);
}
// 用户余额划转
export function transfer(data) {
  return axios.post(`game/transfer`, data);
}
// 查询游戏余额
export function checkUserBalance(data) {
  return axios.post(`game/checkUserBalance`, data);
}
// 点击游戏列表
export function queryGameList() {
  return axios.get(`game/list`);
}

//游戏首页跑马灯
export function gamePMD(data) {
  return axios.get(`game/announcement`, { params: data });
}

//游戏钱包余额
export function gameBalance(data) {
  return axios.get(`game/getBalance`, data);
}

//获取充值游戏币金额
export function gameCurrency(data) {
  return axios.get(`mine/currencys`, { params: data });
}

//获取游戏账单
export function gameBill(data) {
  return axios.post(`game/dongfang/translog`, data);
}
